import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import SecondaryHeader from "../../components/SecondaryHeader"
import SupportCenterTopicWrapper from "../../components/SupportCenterLayout"
import CustomLink from "../../components/CustomLink"

const RegisteringOnAlignerBasePage = ({ data }) => {
  return (
    <Layout>
      <Seo title={`Easy to use cloud platform`} />
      <SecondaryHeader title={<>Support Center</>} />

      <div id="support-center-page">
        <section className="support-center">
          <div style={{ paddingBottom: "50px" }}></div>

          <SupportCenterTopicWrapper>
            <div className="support-center__topic-right">
              <h3 className="mb-4">Registering on AlignerBase</h3>

              <p>
                Get started on your journey to a perfect smile by easily
                registering and activating your AlignerBase account!
              </p>

              <div className="mb-4" />

              <ol className="list-decimal mx-4">
                <li>
                  Go to
                  <CustomLink title="URL" link="/doctor-registration" />
                  <CustomLink title="URL" link="#" />
                  , and click on the Sign-Up link located at the top-right next
                  to Login.
                  <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/1.jpg" />
                  <ul class="list-disc pl-4">
                    <li>
                      For a limited time period, AlignerBase is offering 1 Free
                      Trial order to all new customer. You will by default be
                      eligible to avail the Free Trial order.
                    </li>
                    <li>
                      The Pay As You Go (PAYG) plan will be selected
                      automatically after the Free Trial offer has been
                      consumed. Customers can also subscribe to the Enterprise
                      Plan at this stage. View Plans and Pricing here.
                    </li>
                  </ul>
                </li>
                <li>
                  Add the required information:
                  <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/2.jpg" />
                  <ul class="list-disc pl-4">
                    <li>Name</li>
                    <li>Email Address</li>
                    <li>Gender</li>
                    <li>Region</li>
                    <li>Phone Number</li>
                    <li>Website (Optional)</li>
                    <li>Business Address</li>
                    <li>Where did you hear about AlignerBase?</li>
                  </ul>
                </li>
                <li>
                  Input your password.
                  <ul class="list-disc pl-4">
                    <li>
                      For security, your password must have:
                      <ul class="list-disc pl-4">
                        <li>Minimum 8 characters</li>
                        <li>A special character (!@#$%^&*)</li>
                        <li>A capital (uppercase) letter (A-Z)</li>
                        <li>A number (0-9)</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  In order for creation of your account, we will need your
                  credit card information.
                  <ul class="list-disc pl-4">
                    <li>
                      This is only for validation, and you will not be charged
                      at this time.
                    </li>
                    <li>
                      You will be able to add or delete payment methods from
                      your account later
                    </li>
                    <li>
                      Your payment information will be held securely through
                      Stripe.
                    </li>
                  </ul>
                </li>
                <li>
                  You should receive an email with confirmation of your account.
                  <ul class="list-disc pl-4">
                    <li>
                      Activation email needs to be opened and the link within
                      needs to be clicked for validation.
                    </li>
                    <li>
                      Please check all folders if you don't find it in your main
                      inbox.
                    </li>
                  </ul>
                </li>
              </ol>
            </div>
          </SupportCenterTopicWrapper>
        </section>
      </div>
    </Layout>
  )
}

export default RegisteringOnAlignerBasePage
